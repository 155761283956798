$color-beige:#F3F0EC;
$color-text-light: #737373;
$color-text-dark: #000000;
$color-dark-blue: #0A2D62;

.tui-grid-leg-row {
    background-color: $color-beige;
    color:$color-text-light;
}

.tui-grid-service-type-row {
    background-color: $color-beige;
    color: $color-text-dark;
    font-weight:bold;
}

.tui-grid-alternate-row {
    background-color: $color-beige;
}

h2 {
    .MuiTypography-subtitle1 {
        color:$color-dark-blue;
    }
}

.MuiInputLabel-formControl {
    background-color:white;
}

.errorCell {
    background-color: rgb(126,10,15, 0.1);
}